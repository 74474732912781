<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import effectUnderline from "@/components/hoverUnderlineP.vue";

export default {
  components: {
    effectUnderline,
  },
  data() {
    return {};
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.navBarBackgroundAnimation();
  },

  methods: {
    navBarBackgroundAnimation() {
      gsap.to(".navbar-container", {
        scrollTrigger: {
          trigger: ".navbar-container",
          start: "top top",
          end: "bottom top",
          scrub: true,
        },
        backgroundColor: "rgba(255, 247, 247, 0.05)",
        backdropFilter: "blur(10px)",
      });
    },
  },
};
</script>
<template>
  <div class="navbar-container">
    <div class="navbar-logo">
      <router-link to="/homePagePrivate">
        <svg
          width="147"
          height="33"
          viewBox="0 0 147 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_201_752)">
            <path
              d="M44.1534 28.889H45.1982C45.1982 29.8625 45.894 30.3647 46.951 30.3647C47.8497 30.3647 48.4908 29.9079 48.4908 29.1742C48.4908 28.4404 47.726 28.0746 46.8618 27.7439C45.6932 27.3098 44.3563 26.8737 44.3563 25.2719C44.3563 23.8747 45.535 23.0975 46.7826 23.0975C48.1196 23.0975 49.3104 23.9222 49.3104 25.4889H48.2656C48.2656 24.596 47.659 24.1268 46.7826 24.1268C45.9955 24.1268 45.3889 24.5505 45.3889 25.2719C45.3889 26.0842 46.0969 26.4045 46.9612 26.7373C48.1399 27.1941 49.5336 27.6302 49.5336 29.1742C49.5336 30.5817 48.3204 31.4064 46.949 31.4064C45.4315 31.4064 44.1514 30.5363 44.1514 28.889H44.1534Z"
              fill="white"
            />
            <path
              d="M50.3555 28.397C50.3555 26.7022 51.6701 25.3876 53.3093 25.3876C54.9485 25.3876 56.2753 26.7042 56.2753 28.397C56.2753 30.0898 54.9505 31.4064 53.3093 31.4064C51.6681 31.4064 50.3555 30.0898 50.3555 28.397ZM55.2975 28.397C55.2975 27.2416 54.4332 26.3591 53.3093 26.3591C52.1854 26.3591 51.3313 27.2396 51.3313 28.397C51.3313 29.5545 52.1955 30.435 53.3093 30.435C54.4231 30.435 55.2975 29.5545 55.2975 28.397Z"
              fill="white"
            />
            <path
              d="M61.8235 25.5365V31.2597H60.8457V30.5507C60.442 31.1811 59.8678 31.4085 59.1821 31.4085C58.1373 31.4085 57.2954 30.5962 57.2954 29.2445V25.5365H58.2733V29.2114C58.2733 30.0361 58.7683 30.4598 59.4418 30.4598C60.1153 30.4598 60.8457 30.0257 60.8457 28.7897V25.5385H61.8235V25.5365Z"
              fill="white"
            />
            <path
              d="M67.8348 27.5393V31.2597H66.8569V27.5847C66.8569 26.7601 66.3619 26.3363 65.6884 26.3363C65.0148 26.3363 64.2845 26.7704 64.2845 27.9961V31.2576H63.3066V25.5344H64.2845V26.2433C64.6882 25.6129 65.2725 25.3856 65.9481 25.3856C66.9929 25.3856 67.8348 26.1979 67.8348 27.5372V27.5393Z"
              fill="white"
            />
            <path
              d="M74.8114 23.2484V31.2597H73.8335V30.2862C73.3831 30.9724 72.6548 31.4085 71.7094 31.4085C70.1595 31.4085 68.8672 30.0919 68.8672 28.3991C68.8672 26.7063 70.1595 25.3897 71.7094 25.3897C72.6528 25.3897 73.3831 25.8238 73.8335 26.512V23.2505H74.8114V23.2484ZM73.8335 28.3991C73.8335 27.2313 72.9693 26.3508 71.8332 26.3508C70.6971 26.3508 69.845 27.2313 69.845 28.3991C69.845 29.5669 70.7093 30.4474 71.8332 30.4474C72.9571 30.4474 73.8335 29.5669 73.8335 28.3991Z"
              fill="white"
            />
            <path
              d="M83.1254 29.7033C83.1254 30.7326 82.2266 31.4085 80.979 31.4085C79.7313 31.4085 78.8447 30.7223 78.8447 29.5421H79.8104C79.8104 30.2283 80.3379 30.4805 80.979 30.4805C81.62 30.4805 82.1475 30.2407 82.1475 29.7012C82.1475 28.4074 78.934 29.2445 78.934 27.0928C78.934 26.0635 79.8327 25.3876 80.979 25.3876C82.1252 25.3876 83.0117 26.0738 83.0117 27.254H82.0461C82.0461 26.5678 81.5186 26.3157 80.979 26.3157C80.4393 26.3157 79.9118 26.5554 79.9118 27.0949C79.9118 28.3888 83.1254 27.5413 83.1254 29.7033Z"
              fill="white"
            />
            <path
              d="M86.8885 26.4976H85.7869V31.2597H84.809V26.4976H83.6973V25.5365H84.809V23.7052H85.7869V25.5365H86.8885V26.4976Z"
              fill="white"
            />
            <path
              d="M92.2244 25.5365V31.2597H91.2466V30.5507C90.8429 31.1811 90.2687 31.4085 89.583 31.4085C88.5382 31.4085 87.6963 30.5962 87.6963 29.2445V25.5365H88.6741V29.2114C88.6741 30.0361 89.1692 30.4598 89.8427 30.4598C90.5162 30.4598 91.2466 30.0257 91.2466 28.7897V25.5385H92.2244V25.5365Z"
              fill="white"
            />
            <path
              d="M99.3347 23.2484V31.2597H98.3569V30.2862C97.9085 30.9724 97.1782 31.4085 96.2348 31.4085C94.6849 31.4085 93.3926 30.0919 93.3926 28.3991C93.3926 26.7063 94.6849 25.3897 96.2348 25.3897C97.1782 25.3897 97.9085 25.8238 98.3569 26.512V23.2505H99.3347V23.2484ZM98.3569 28.3991C98.3569 27.2313 97.4926 26.3508 96.3566 26.3508C95.2205 26.3508 94.3684 27.2313 94.3684 28.3991C94.3684 29.5669 95.2326 30.4474 96.3566 30.4474C97.4805 30.4474 98.3569 29.5669 98.3569 28.3991Z"
              fill="white"
            />
            <path
              d="M101.307 25.5365C101.577 25.5365 101.796 25.7597 101.796 26.0346V31.2597H100.818V26.0346C100.818 25.7597 101.037 25.5365 101.307 25.5365Z"
              fill="white"
            />
            <path
              d="M102.977 28.397C102.977 26.7022 104.291 25.3876 105.93 25.3876C107.57 25.3876 108.896 26.7042 108.896 28.397C108.896 30.0898 107.572 31.4064 105.93 31.4064C104.289 31.4064 102.977 30.0898 102.977 28.397ZM107.919 28.397C107.919 27.2416 107.054 26.3591 105.93 26.3591C104.806 26.3591 103.952 27.2396 103.952 28.397C103.952 29.5545 104.817 30.435 105.93 30.435C107.044 30.435 107.919 29.5545 107.919 28.397Z"
              fill="white"
            />
            <path
              d="M0.000126152 16.5455C0.0366434 7.47802 7.14533 0.161219 15.8973 0.186022C24.706 0.210824 31.7701 7.59376 31.7762 16.6674C31.7823 25.3711 24.7101 33.1798 15.4043 32.9979C7.00737 32.8346 -0.0343623 25.4124 0.000126152 16.5455Z"
              fill="#8A0917"
            />
            <path
              d="M56.6081 5.8183C56.6081 2.59395 54.062 0 50.8972 0H44.2349V16.9692H48.0428V3.63566H50.8972C51.9441 3.63566 52.8002 4.58023 52.8002 5.8183C52.8002 7.05637 51.9441 8.00094 50.8972 8.00094H48.7691V11.3948H49.8504L52.9909 16.9713H57.0828L53.5366 10.7416C55.3686 9.795 56.6061 7.90586 56.6061 5.82037L56.6081 5.8183Z"
              fill="white"
            />
            <path
              d="M63.8892 4.50789C60.13 4.50789 57.5596 7.29613 57.5596 10.907C57.5596 14.5178 60.0813 17.3061 64.1509 17.3061C66.4109 17.3061 68.1719 16.4587 69.3384 14.8093L66.484 13.1372C66.0072 13.6704 65.1754 14.059 64.1996 14.059C62.8911 14.059 61.7956 13.6229 61.3452 12.3621H69.7928C69.8882 11.9012 69.9348 11.4175 69.9348 10.907C69.9348 7.24652 67.3644 4.50789 63.8912 4.50789H63.8892ZM61.2722 9.64618C61.6049 8.41018 62.5097 7.73017 63.8649 7.73017C64.936 7.73017 66.0072 8.23863 66.3866 9.64618H61.2701H61.2722Z"
              fill="white"
            />
            <path
              d="M80.472 5.98779C79.6869 5.06595 78.5204 4.50996 76.9258 4.50996C73.8076 4.50996 71.2393 7.2982 71.2393 10.9091C71.2393 14.5199 73.8097 17.3082 76.9258 17.3082C78.5204 17.3082 79.6849 16.7501 80.472 15.8303V16.9692H84.0406V0H80.472V5.98779ZM77.6399 13.8668C75.9987 13.8668 74.8078 12.7279 74.8078 10.9091C74.8078 9.09019 75.9966 7.95133 77.6399 7.95133C79.2832 7.95133 80.472 9.09019 80.472 10.9091C80.472 12.7279 79.2832 13.8668 77.6399 13.8668Z"
              fill="white"
            />
            <path
              d="M99.0309 0H92.7256V16.9692H96.5335V3.63566H99.0329C100.199 3.63566 101.056 4.58023 101.056 5.8183C101.056 7.05637 100.199 8.00094 99.0329 8.00094H97.2618V11.6366H99.0329C102.317 11.6366 104.861 9.04265 104.861 5.8183C104.861 2.59395 102.315 0 99.0309 0Z"
              fill="white"
            />
            <path
              d="M111.644 4.50789C108.146 4.50789 105.338 7.29613 105.338 10.907C105.338 14.5178 108.146 17.3061 111.644 17.3061C115.141 17.3061 117.949 14.5178 117.949 10.907C117.949 7.29613 115.141 4.50789 111.644 4.50789ZM111.644 13.7676C110.098 13.7676 108.907 12.6287 108.907 10.907C108.907 9.18527 110.096 8.04641 111.644 8.04641C113.192 8.04641 114.38 9.18527 114.38 10.907C114.38 12.6287 113.192 13.7676 111.644 13.7676Z"
              fill="white"
            />
            <path
              d="M121.755 4.67738C120.769 4.67738 119.97 5.50207 119.97 6.52104V16.9692H123.538V6.52104C123.538 5.50207 122.739 4.67738 121.753 4.67738H121.755Z"
              fill="white"
            />
            <path
              d="M133.128 4.50789C131.558 4.50789 130.367 5.08868 129.726 5.98572V4.84686H126.157V16.9671H129.726V10.3489C129.726 8.6272 130.631 7.85212 131.939 7.85212C133.081 7.85212 134.008 8.55486 134.008 10.0575V16.9671H137.577V9.52424C137.577 6.25235 135.53 4.50582 133.128 4.50582V4.50789Z"
              fill="white"
            />
            <path
              d="M147 8.33991V4.84893H144.55V1.45509L140.981 2.54641V4.84893H139.078V8.33991H140.981V12.6308C140.981 16.0246 142.338 17.4549 147 16.9692V13.6725C145.43 13.7696 144.55 13.6725 144.55 12.6308V8.33991H147Z"
              fill="white"
            />
            <path d="M38.414 0H37.3145V33H38.414V0Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_201_752">
              <rect width="147" height="33" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </router-link>
    </div>
    <div class="navbar-links">
      <effectUnderline
        navBarText="Tutoriels"
        navBarUrl="/homePagePrivate#tutorialSection"
      />
      <effectUnderline
        navBarText="Réservation"
        navBarUrl="/homePagePrivate#navPrivateAcces"
      />
      <effectUnderline navBarText="Infos pratiques" navBarUrl="/infosPage" />
      <effectUnderline navBarText="Sommaire" navBarUrl="/summaryPage" />
    </div>
  </div>
</template>

<style scoped>
.navbar-links {
  margin-right: 3.5%;
  width: 30%;
}
</style>
